<template>
  <div class="help-center">
    <Header></Header>
    <div class="help-center-main">
      <ul>
        <li v-for="item in helpList"
          :key="item.lael"
          class="help-center-group"
        >
          <div :class="handleGroup(item)" @click="handleGroupClick(item)">
            <div>{{item.label}}</div>
            <img alt>
          </div>
          <div class="help-center-group-line"></div>
          <ul
            class="help-center-child" 
            v-if="item.isSelect"
          >
            <li v-for="contentItem in item.childList"
              :key="contentItem.lael"
              class="help-center-child-item"
            >
              <dt>{{contentItem.title}}</dt>
              <dd>{{contentItem.content}}</dd>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/mobile/header'
import Footer from '@/components/mobile/footer'

export default {
  components: { Header, Footer },

  data() {
    return {
      helpList:[
        { 
          label: "关于易借",
          isSelect: false,
          childList: [
            { 
              title: "什么是易借速贷？",
              content: "易借速贷是一个面向广大用户群体的金融科技服务平台，平台上所展示的所有金融产品均来自于正规金融机。" 
            },
            { 
              title: "易借速贷有哪些特色？",
              content: "易借速贷是一个面向广大用户群体的金融科技服务平台，平台上所展示的所有金融产品均来自于正规金融机。" 
            },
            { 
              title: "使用易借平台申请是否安全？",
              content: "用户在易借平台首次申请借款时需通过身份证验证，人脸识别，手机实名，短信验证，且款项只能放款到用户本人名下的银行卡，请放心使用。" 
            }

          ] 
        },
        { 
          label: "申请问题",
          isSelect: false,
          childList: [
            { 
              title: "申请条件",
              content: "用户须是年龄在23-54周岁（年龄限制或有调整，具体以实际规则为准）的自然人，且非在校学生。申请过程中需要提供您的二代身份证，本人借记卡。" 
            },
            { 
              title: "申请流程",
              content: "当您用手机打开易借速贷APP后，点击领取额度→填写资料→完成身份验证→获得额度。" 
            },
            { 
              title: "审核时间",
              content: "易借速贷采用实时审核，最快10秒钟审核，最高20万额度。" 
            },
            { 
              title: "为什么授信失败？",
              content: "额度申请的综合评分是系统判定的，有严密的风险控制等诸多因素。" 
            },
            { 
              title: "填写信息注意事项",
              content: "申请支持借记卡（建议1类卡）。由于部分网络问题验证码会发送较慢，请不要频繁点击“发送验证码”，在获得验证码后请在30分钟内完成申请。本人身份信息需为二代身份证信息，不能使用临时身份证、过期身份证、一代身份证进行申请。请填写紧急联系人的真实姓名，以保证顺利通过审核。" 
            },
            { 
              title: "关于征信",
              content: "“易借速贷“已与锡商银行、大兴安岭银行、马上消费金融、万方小贷等金融机构展开合作，并已接入银行征信系统，所有用户的逾期情况都会在个人征信记录里中体现，同时与多家第三方征信机构、反诈骗机构展开合作，共同建立风险控制与反欺诈服务数据库，会在行业系统中披露失信信息。" 
            }
          ] 
        },
        { 
          label: "借款问题",
          isSelect: false,
          childList: [
            { 
              title: "易借速贷的借款流程是什么?",
              content: "打开易借速贷App后，点击领取额度→填写资料→完成身份验证→获得额度→申请借款→资金到账。最快10秒钟到账。" 
            },
            { 
              title: "易借速贷用户验证码异常怎么办？",
              content: "在易借平台发生借款时验证码接收异常可能是多种因素导致的。建议确认以下情况：如果有安装第三方通讯类或安全管理类软件（如360手机卫士），建议更改软件设置。  拔出SIM卡，安装到其他的手机端做下重新开关机处理，然后再换回到原手机端重试。若排除以上情况后接收验证码仍异常，请联系平台客服。" 
            },
            { 
              title: "易借速贷借款利率是多少?",
              content: "易借速贷平台用户借款费率是系统根据用户的个人信息综合评估判定的，具体以额度申请成功后App首页显示为准。保持良好的借款、还款习惯有助于获得更低的借款费率。" 
            },
            { 
              title: "用户在易借速贷平台上费率变化了，尚未结清的借款费率会有变化吗？",
              content: "用户在易借速贷平台上申请借款的费率变化仅针对变化后新发生的借款，在费率变化前已有的借款仍按原政策执行。" 
            },
            { 
              title: "用户从易借速贷平台获得的借款去向？",
              content: "为保证安全，借款将发放至用户第一次绑定的用户本人银行卡上。" 
            },
            { 
              title: "易借速贷平台申请的借款可以借多久？",
              content: "用户可选择进行分期还款，最低6期，最高12期。" 
            },
            { 
              title: "用户在易借速贷平台上费率变化了，尚未结清的借款费率会有变化吗？",
              content: "用户在易借速贷平台上申请借款的费率变化仅针对变化后新发生的借款，在费率变化前已有的借款仍按原政策执行。" 
            },
            { 
              title: "借款有次数限制吗？",
              content: "用户在借款额度和授信期限内可循环无限次使用，但再次申请借款前须结清已有的借款。" 
            },
            { 
              title: "为什么我的借款显示待放款状态？",
              content: "用户申请的“易借速贷”借款已审核通过，但由于人气火爆，放款时间会根据实际情况有所延迟，需要用户于预计放款日上午10:00尽早登录APP确认放款信息，先到先得。" 
            }
          ] 
        },
        { 
          label: "还款问题",
          isSelect: false,
          childList: [
            { 
              title: "在哪里进行还款?",
              content: "用户可登录易借速贷产品【首页】-点击【去还钱】-跳转至【未还账单】进行还款。" 
            },
            { 
              title: "可以分期还款么?",
              content: "借款后系统已根据用户综合情况给出不同的分期数，具体以借款页面显示期数为准。" 
            },
            { 
              title: "如果没按时还款，会有什么影响?",
              content: "用户应于还款日起按时足额还款，超期还款的即视为逾期，逾期将收取罚息。同时，您的逾期记录将被上报人行征信，将影响您的信用记录，珍爱信用，请按时还款。" 
            },
            { 
              title: "还款有时间限制吗？",
              content: "不排除存在银行结算系统维护或升级、网络故障或维护等情况影响还款，建议用户于22:00前完成还款。如遇还款问题，请及时在21:00前联系客服处理。" 
            },
            { 
              title: "还款时间可以修改吗？",
              content: "还款日由系统根据用户的借款情况自行设定，暂不支持延期或修改。用户须按时还款，避免因借款逾期产生不利影响。" 
            },
            { 
              title: "是否可以提前还款?",
              content: "除借款当天，您可以发起整笔提前结清或当月提前还款，具体以页面展示为准。" 
            },
            { 
              title: "整笔提前结清利息如何计算?",
              content: "整笔提前结清时，系统将自动计算您的借款截止到当天的利息。利息按实际借款天数计算，具体以页面为准。" 
            },
            { 
              title: "什么是自动还款?",
              content: "自动还款是指在还款当日自动从绑定银行卡里扣除当期应还金额。自动还款可以帮助用户避免逾期，需用户在还款当日前确保还款银行卡余额充足。另外，如遇银行卡状态异常、银行卡限额问题等特殊情况导致自动还款失败，需请用户自行前往易借速贷平台手动操作还款。" 
            }
          ] 
        },
        { 
          label: "更多问题",
          isSelect: false,
          childList: [
            { 
              title: "",
              content: "请您拨打全国统一客服电话：400-777-8802进行咨询，客服工作时间为每日9:00至21:00。" 
            }
          ] 
        }
      ]
    }

  },
  methods: {
    //根据当前选中状态选择class
    handleGroup (val) {
      if (val.isSelect) {
        return "help-center-group-select"
      }
      return "help-center-group-normal"
    },
    //
    handleGroupClick (val) {
      val.isSelect = !val.isSelect
    },
  }

}
</script>

<style lang="less">
  .help-center {
    display: flex;
    flex-direction: column;

    &-main {
      display: flex;
      flex-direction: column;
      margin-top: 44px;
      margin-bottom: 53px;
    }

    &-group {
      display: flex;
      flex-direction: column;

      &-normal {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 27px;
        padding-right: 12px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #1e1f20;
          line-height: 20px;
        }

        img {
          width: 24px;
          height: 24px;
          content: url('../../assets/img/mobile/helpCenter/ic_arrow_normal.png');
        }
      }

      &-select {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 27px;
        padding-right: 12px;
        background: #ffffff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #ee6c28;
          line-height: 20px;
        }

        img {
          width: 24px;
          height: 24px;
          content: url('../../assets/img/mobile/helpCenter/ic_arrow_select.png');
        }

      }

      &-line {
        width: 100%;
        height: 1px;
        background: #f2f2f2;
      }

    }
    
    &-child {
      padding-bottom: 56px;
      background: #f7f7f7;

      &-item {
        margin-top: 34px;
        margin-left: 38px;
        margin-right: 38px;

        dt {
          font-size: 15px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          text-align: left;
          color: #1e1f20;
          line-height: 21px;  
        }

        dd {
          margin-top: 7px;
          font-size: 13px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: justify;
          color: #4f4f4f;
          line-height: 22px;
        }
      }
    }
  }
</style>>