<template>
  <div class="mobile-footer">
    <div class="mobile-footer-title" style="margin-top:37px">联系我们</div>
    <div class="mobile-footer-txt" style="margin-bottom: 10px;">
      客服电话：400-777-8802 (9:00-21:00)<br>
      投诉电话：0571-87608950 (工作日 9:00-18:00)<br>
      商务合作：17706521236<br>
      媒体联系：13804087220
    </div>
    <!-- <div class="mobile-footer-title">
      <h2>官方微信公众号</h2>
      <img class="mobile-footer-qrCode" src="@/assets/img/pc/qrCode.jpeg">
    </div> -->
    <div class="mobile-footer-tips">
      金融服务将根据您的个人情况由适合的正规金融机构提供；<br>
      综合年化利率以最终审批结果为准；平台承诺不额外向用户收取费用；<br>
      借款实际到账时间（提交借款申请到资金到账时间）视机构放款速度而定；请根据个人能力合理贷款，理性消费，避免逾期。
    </div>
    <div class="mobile-footer-line" style="margin-top:46px"></div>
    <div class="mobile-footer-bottom">
      <p>Copyright ©2018 浙江未讯科技有限公司</p>
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"> 浙ICP备18045556号</a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.mobile-footer {
  background: #2c3143;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-qrCode {
    margin: 10px 0;
    width: 112px;
    height: 112px;
  }

  &-title {
    font-size: 15px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 21px;
    margin-top: 38px;
  }

  &-txt {
    font-size: 13px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 22px;
  }
  &-tips {
    font-size: 12px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: justify;
    color: rgba(255, 255, 255, 0.5);
    line-height: 22px;
    margin: 0 30px;
  }

  &-line {
    height: 1px;
    transform: scaleY(0.5);
    width: 100%;
    opacity: 0.5;
    background: #ffffff;
  }
  &-bottom {
    margin: 10px 0;
    text-align: center;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.5);
    font-family: PingFangSC, PingFangSC-Regular;
    a {
      line-height: 30px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
