<template>
  <div class="header">
    <div class="header-main">
      <div class="header-content">
        <div class="header-left">
          <img
            class="header-left-logo"
            src="@/assets/img/mobile/logo.png"
            alt
          >
          <div class="header-left-line"></div>
          <img
            class="header-left-logo-txt"
            src="@/assets/img/mobile/logo_txt.png"
            alt
          >
        </div>
        <img
          class="header-menu"
          :src="menuUrl"
          @click="hanldeMenuSelect"
        >
      </div>
      <div class="header-line"></div>
    </div>
    <div
      v-if="menuVisible"
      class="header-menu-background"
    ></div>
    <ul
      v-if="menuVisible"
      class="header-menu-content"
    >
      <li
        class="header-menu-item"
        v-for="(item, index) in menuItemList"
        :key="index"
        @click="handleMenuItemSelect(index)"
      >
        <div class="header-menu-item-content">
          <img
            :src="item.imgUrl"
            class="header-menu-item-img"
          >
          <div :class="item.style">{{item.title}}</div>
        </div>
        <div class="header-menu-item-line"></div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  created () {
    let index = localStorage.getItem('headerIndex')
    index = index ? index : 0
    this.handleMenuItemSelect(index)
  },
  data () {
    return {
      menuVisible: false,
      selectIndex: 0,
      menuUrl: require('@/assets/img/mobile/menu.png'),
      menuItemList: [
        {
          title: "首页",
          imgUrl: require('@/assets/img/mobile/header/menu_home_select.png'),
          style: "header-menu-item-txt-select",
          path: "/m/home"
        },
        {
          title: "帮助中心",
          imgUrl: require('@/assets/img/mobile/header/menu_help.png'),
          style: "header-menu-item-txt",
          path: "/m/helpCenter"
        },
        {
          title: "联系我们",
          imgUrl: require('@/assets/img/mobile/header/menu_contact.png'),
          style: "header-menu-item-txt",
          path: "/m/contactus"
        }
      ],
      menuIcon: {
        imgUrl: require('@/assets/img/mobile/header/menu.png'),
        imgSelectUrl: require('@/assets/img/mobile/header/menu_select.png'),
      },
      iconList: [
        {
          imgUrl: require('@/assets/img/mobile/header/menu_home.png'),
          imgSelectUrl: require('@/assets/img/mobile/header/menu_home_select.png'),
        },
        {
          imgUrl: require('@/assets/img/mobile/header/menu_help.png'),
          imgSelectUrl: require('@/assets/img/mobile/header/menu_help_select.png'),
        },
        {
          imgUrl: require('@/assets/img/mobile/header/menu_contact.png'),
          imgSelectUrl: require('@/assets/img/mobile/header/menu_contact_select.png'),
        }
      ]
    }
  },
  methods: {
    //
    hanldeMenuSelect () {
      if (this.menuVisible) {
        this.menuUrl = this.menuIcon.imgUrl
      } else {
        this.menuUrl = this.menuIcon.imgSelectUrl
      }
      this.menuVisible = !this.menuVisible
    },
    //
    handleMenuItemSelect (index) {
      if (this.selectIndex != index) {
        //重置当前选中
        this.menuItemList[this.selectIndex].imgUrl = this.iconList[this.selectIndex].imgUrl
        this.menuItemList[this.selectIndex].style = "header-menu-item-txt"
        //重新选择
        this.menuItemList[index].imgUrl = this.iconList[index].imgSelectUrl
        this.menuItemList[index].style = "header-menu-item-txt-select"
        //重新赋值并进行路由跳转
        this.selectIndex = index
        this.$router.push({ path: this.menuItemList[this.selectIndex].path })
        localStorage.setItem('headerIndex', this.selectIndex)
      }
    }
  }
}

  const w = window
  const d = document
  const n = 'ysf'
  var a = function (w, d, n, a, j) {
    w[n] = w[n] || function () {
      (w[n].a = w[n].a || []).push(arguments)
    }
    const script = document.createElement('script')
    script.setAttribute('src', 'https://qiyukf.com/script/340306fe6b76eecbbbfce5dc3fe49f2d.js?hidden=1')
    document.body.appendChild(script)
  }
  a(w, d, n)


</script>

<style lang="less">
.header {
  width: 100%;
  position: fixed;

  &-main {
    display: flex;
    flex-direction: column;
  }

  &-line {
    width: 100%;
    height: 1px;
    background: #f2f2f2;
  }

  &-content {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
  }

  &-left {
    display: flex;
    flex-direction: row;
    margin-left: 34px;

    &-logo {
      width: 36px;
      height: 20px;
    }

    &-line {
      height: 18px;
      width: 1px;
      background: #e3e3e3;
      margin-left: 8px;
      margin-right: 8px;
    }

    &-logo-txt {
      width: 76px;
      height: 18px;
    }
  }

  &-menu-background {
    width: 100%;
    height: 10000px;
    top: 100%;
    position: absolute;
    background: rgba(00, 00, 00, 0.3);
  }

  &-menu {
    width: 24px;
    height: 24px;
    margin-right: 34px;

    &-content {
      position: absolute;
      right: 0;
      top: 100%;
      background: #ffffff;
    }

    &-item {
      right: 0;

      &-content {
        width: 146px;
        height: 54;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 17px;
        padding-bottom: 17px;
      }

      &-line {
        height: 1px;
        width: 146px;
        background: #f2f2f2;
      }

      &-img {
        width: 18px;
        height: 18px;
        margin-left: 12px;
        margin-right: 10px;
      }

      &-txt {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #1e1f20;
        line-height: 20px;
      }

      &-txt-select {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: center;
        color: #ee6c28;
        line-height: 20px;
      }
    }
  }
}
</style>